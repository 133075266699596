<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">{{ totalProducts }} resultados</div>
            </div>
            <div class="view-options d-flex flex-wrap">
              <b-button
                class="mr-1 mb-2"
                variant="success"
                @click="$bvModal.show('scanner')"
              >
                <i class="fas fa-qrcode" />
              </b-button>
              <b-modal id="scanner">
                <StreamBarcodeReader @decode="onDecode" />
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->

    <div v-if="fetchState.storeProducts.firstLoad">
      <b-skeleton-img height="50px" no-aspect></b-skeleton-img>
    </div>

    <div v-else class="ecommerce-searchbar mt-1">
      <b-input-group class="input-group-merge">
        <b-form-input
          v-model="filters.q"
          placeholder="Buscar producto"
          class="search-product"
        />
        <b-input-group-append v-if="filters.q">
          <b-button
            variant="outline-warning"
            @click="clearSearchbarAndResetSearch()"
          >
            Borrar
          </b-button>
        </b-input-group-append>
        <b-input-group-append is-text>
          <feather-icon icon="SearchIcon" class="text-muted" />
        </b-input-group-append>
      </b-input-group>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <div v-if="products.length <= 0 && !fetchState.storeProducts.loading">
        <b-card class="ecommerce-card mb-1" no-body>
          <b-card-body>
            <p>
              Agrega uno de los paquetes existentes para facilitar el registro
              de productos en tu punto de venta. Los productos se darán de alta
              con un precio y costo sugerido que podrás modificar en cualquier
              momento
            </p>
          </b-card-body>
        </b-card>
        <b-card>
          <b-row v-for="productPackage in packages" :key="productPackage.id">
            <b-col>
              <h6 class="item-name">
                {{ productPackage.name }}
              </h6>
              <b-link class="text-muted">
                {{ productPackage.product_package_items.length }} productos
              </b-link>
            </b-col>
            <b-col sm="auto">
              <b-button
                variant="success"
                @click="
                  showConfirmationModalForSelectedPackage(productPackage.id)
                "
              >
                <feather-icon icon="PlusIcon" />
                Seleccionar paquete
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div v-else>
        <div v-if="fetchState.storeProducts.error">
          <b-card class="mb-1" no-body>
            <b-card-body>
              <p>Ocurrió un error al cargar los productos</p>
              <b-button
                :disabled="fetchState.storeProducts.loading"
                variant="primary"
                @click="getStoreProducts({ checkAttemps: true })"
                class="mt-1"
              >
                Reintentar
              </b-button>
            </b-card-body>
          </b-card>
        </div>

        <div v-if="fetchState.storeProducts.firstLoad">
          <div v-for="n in 10" class="mb-1 mt-1">
            <b-skeleton-img height="175px" no-aspect></b-skeleton-img>
          </div>
        </div>

        <overlay v-else v-show="!fetchState.storeProducts.loading">
          <b-card
            v-for="product in products"
            :key="product.id"
            class="ecommerce-card mb-1"
            no-body
          >
            <div
              class="item-img text-center d-flex justify-content-center px-1"
            >
              <b-link
                :to="{
                  name: 'store-product',
                  params: {
                    id: $router.currentRoute.params.id,
                    store_product_id: product.id,
                  },
                }"
              >
                <b-img
                  :alt="`${product.product_attributes.name}-${product.id}`"
                  fluid
                  rounded
                  :src="`${product.product_attributes.logo}`"
                  :width="itemView === 'list-view' ? 110 : 200"
                />
              </b-link>
            </div>

            <!-- Product Details -->
            <b-card-body class="position-relative">
              <div class="item-wrapper">
                <div class="item-rating">
                  <nutri-score
                    v-if="product.product_attributes.is_edible"
                    :nutritional-info="
                      product.product_attributes.nutritional_info
                    "
                    :class="{ 'mt-1': itemView === 'list-view' }"
                  />
                </div>
                <div>
                  <h6 class="item-price">${{ product.unit_price | money }}</h6>
                  <h6 class="item-price">${{ product.unit_cost | money }}</h6>
                </div>
              </div>
              <h6 class="item-name">
                <b-link
                  class="text-body"
                  :to="{
                    name: 'store-product',
                    params: {
                      id: $router.currentRoute.params.id,
                      store_product_id: product.id,
                    },
                  }"
                >
                  {{ product.product_attributes.name }}
                </b-link>
                <b-link class="text-muted">
                  {{ product.product_attributes.variant }}
                </b-link>
                <p class="text-muted">
                  Por {{ product.unit_code_name }}
                </p>
                <b-row>
                  <b-col>
                    <b-badge
                      v-for="(
                        variant, n
                      ) in product.store_product_variant_attributes"
                      :key="`variant-${n}`"
                      class="mr-1 mt-1"
                      pill
                      variant="light-info"
                    >
                      {{
                        variant.variant_option_attributes.variant_attributes
                          .name
                      }}: {{ variant.variant_option_attributes.option_name }}
                    </b-badge>
                  </b-col>
                </b-row>
                <b-badge class="mr-1 mt-1" pill variant="light-warning">
                  {{
                    product.product_attributes.product_type === "product"
                      ? "Producto "
                      : "Servicio "
                  }}
                  {{ product.is_private ? "privado" : "publico" }}
                </b-badge>
              </h6>
              <b-card-text class="item-description mt-1">
                <b-badge
                  v-for="(category, n) in product.product_attributes
                    .categories_names"
                  :key="`category-${n}`"
                  class="mr-1"
                >
                  {{ category }}
                </b-badge>
              </b-card-text>
              <div v-if="product.is_favorite" class="starred">
                <i
                  class="fa-star"
                  :class="product.is_favorite ? 'fas text-success' : 'far'"
                />
              </div>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <div class="item-wrapper">
                <div class="item-cost">
                  <h5
                    class="
                      item-price
                      text-success
                      d-flex
                      justify-content-between
                    "
                  >
                    <span>Precio</span> ${{ product.unit_price | money }}
                  </h5>
                  <h5 class="item-price d-flex justify-content-between mt-1">
                    <span>Costo</span> ${{ product.unit_cost | money }}
                  </h5>
                  <h5
                    class="
                      item-price
                      text-secondary
                      d-flex
                      justify-content-between
                      mt-1
                    "
                  >
                    <span>Inventario</span>
                    <div>
                      <span
                        v-if="product.has_inventory"
                        :class="
                          product.inventory > 0
                            ? 'text-secondary'
                            : 'text-danger'
                        "
                      >
                        {{ formatInventory(product.inventory) }}
                      </span>
                      <span v-else> N/D </span>
                    </div>
                  </h5>
                </div>
              </div>
              <b-button
                variant="primary"
                tag="a"
                class="btn-cart"
                :to="{
                  name: 'edit-store-product',
                  params: {
                    store_id: $route.params.id,
                    store_product_id: product.id,
                  },
                }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>Editar</span>
              </b-button>
            </div>
          </b-card>
        </overlay>
      </div>
    </section>

    <!-- Pagination -->
    <section v-if="totalProducts > filters.perPage">
      <pagination
        :pagination="pagination"
        :handlePagination="handlePagination"
        :loading="fetchState.storeProducts.loading"
        :hideEntriesPerPage="true"
      />
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <div v-if="fetchState.categories.error">
        <b-card class="mb-1" no-body>
          <b-card-body>
            <p>Ocurrió un error al cargar las categorías</p>
            <b-button
              :disabled="fetchState.categories.loading"
              variant="primary"
              @click="fetchProductsCategories({ checkAttemps: true })"
              class="mt-1"
            >
              Reintentar
            </b-button>
          </b-card-body>
        </b-card>
      </div>

      <div v-else-if="fetchState.categories.firstLoad">
        <b-skeleton-img height="100vh"></b-skeleton-img>
      </div>

      <shop-left-filter-sidebar
        v-else
        :filters="filters"
        :categories="categories"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :products="products"
      />
    </portal>
  </div>
</template>

<script>
import _ from "underscore"
import router from "@/router"
import { watch, onMounted, ref } from "@vue/composition-api"
import { mapActions } from "vuex"
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
import NutriScore from "@/@core/components/NutriScore.vue"
import ShopLeftFilterSidebar from "@/views/e-commerce/e-commerce-shop/ECommerceShopLeftFilterSidebar.vue"
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "@/views/stores/useStoreProducts"
import { useEcommerceUi } from "@/views/e-commerce/useEcommerce"
import { StreamBarcodeReader } from "vue-barcode-reader"

import Pagination from "@core/components/Pagination.vue"
import messagesMixin from "@/@core/mixins/messagesMixin"
import Overlay from "@core/components/Overlay.vue"

let $this

export default {
  components: {
    Pagination,
    Overlay,
    // SFC
    ShopLeftFilterSidebar,

    // Walleat
    NutriScore,

    StreamBarcodeReader,
  },
  mixins: [messagesMixin],
  setup(x, ctx) {
    const { filters, pagination, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const { itemView, itemViewOptions, totalProducts } = useShopUi()

    const {
      products,
      fetchStoreProducts,
      categories,
      fetchCategories,
      packages,
      fetchProductPackages,
    } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const fetchState = ref({
      storeProducts: {
        error: false,
        loading: false,
        attempts: 0,
        maxAttempts: 3,
        firstLoad: true,
      },
      categories: {
        error: false,
        loading: false,
        attempts: 0,
        maxAttempts: 3,
        firstLoad: true,
      },
    })

    const getStoreProducts = async (
      { page, checkAttemps } = { page: 1, checkAttemps: false }
    ) => {
      if (
        checkAttemps &&
        fetchState.value.storeProducts.attempts >=
          fetchState.value.storeProducts.maxAttempts
      ) {
        $this.toastError({
          title: "Error",
          text: "El servidor no responde, por favor intente más tarde",
        })
        return
      }

      fetchState.value.storeProducts.loading = true
      return fetchStoreProducts({
        by_sku: filters.value.sku || null,
        by_name: !filters.value.sku ? filters.value.q : null,
        by_nutritional_info: filters.value.nutriScore,
        by_active_status: true,
        by_store: ctx.root.$route.params.id || null,
        by_category: filters.value.categories || null,
        ...filters,
        meta: {
          pagination: {
            page: page,
            per_page: filters.value.perPage,
          },
        },
      })
        .then((response) => {
          products.value = response.data
          pagination.value = response.meta.pagination
          totalProducts.value = response.meta.pagination.total_objects
        })
        .catch((error) => {
          console.error(error)
          fetchState.value.storeProducts.attempts++
          fetchState.value.storeProducts.error = true
          $this.toastError({
            title: "Error",
            text: "Ocurrió un error al cargar los productos",
          })
        })
        .finally(() => {
          fetchState.value.storeProducts.loading = false
          fetchState.value.storeProducts.firstLoad = false
        })
    }

    const fetchShopProducts = _.debounce(function () {
      const query = filters.value.q

      if (!query) {
        filters.value.sku = null
        getStoreProducts()
      } else if (/^\d*$/.test(query)) {
        const barcode = query.substring(0, query.length - 1)
        filters.value.sku = Number(barcode)
        getStoreProducts()
      } else {
        filters.value.sku = null
        getStoreProducts()
      }
    }, 500)

    const fetchProductsCategories = async (
      { checkAttemps } = { checkAttemps: false }
    ) => {
      if (
        checkAttemps &&
        fetchState.value.categories.attempts >=
          fetchState.value.categories.maxAttempts
      ) {
        $this.toastError({
          title: "Error",
          text: "El servidor no responde, por favor intente más tarde",
        })
        return
      }

      fetchState.value.categories.attempts++

      fetchState.value.categories.loading = true
      return fetchCategories({
        by_active_status: true,
        by_store: router.currentRoute.params.id,
        meta: {
          pagination: {
            per_page: 10000,
          },
        },
      })
        .then((response) => {
          categories.value = response.data
        })
        .catch(() => {
          console.error(error)
          fetchState.value.categories.attempts++
          fetchState.value.categories.error = true
          $this.toastError({
            title: "Error",
            text: "Ocurrió un error al cargar las categorías",
          })
        })
        .finally(() => {
          fetchState.value.categories.loading = false
          fetchState.value.categories.firstLoad = false
        })
    }

    let promises = []

    if (products.value.length <= 0) {
      const fetchProductPackagesData = () => {
        return fetchProductPackages().then((response) => {
          packages.value = response.data
        })
      }

      promises.push(fetchProductPackagesData())
    }

    onMounted(() => {
      promises.push(getStoreProducts())
      promises.push(fetchProductsCategories())

      Promise.allSettled(promises)
    })

    const formatInventory = (value) => {
      if (value == null) return "0.00"
      return Number(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }

    const handlePagination = ({ page }) => {
      getStoreProducts({ page })
    }

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts()
      },
      {
        deep: true,
      }
    )

    return {
      // useShopFiltersSortingAndPagination
      fetchState,
      pagination,
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      handlePagination,
      fetchProductsCategories,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,
      formatInventory,
      getStoreProducts,

      // useShopRemoteData
      products,
      categories,
      packages,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  created() {
    $this = this
  },
  methods: {
    ...mapActions("stores", ["fetchStoreProducts"]),
    ...mapActions("productPackages", ["dispatchProductPackage"]),
    clearSearchbarAndResetSearch() {
      this.filters.q = ""
    },
    onDecode(result) {
      this.filters.q = result
      this.$bvModal.hide("scanner")
    },
    showConfirmationModalForSelectedPackage(productPackageId) {
      this.$bvModal
        .msgBoxConfirm(
          "¿Estás seguro de la selección del paquete de productos?",
          {
            title: "Mensaje de confirmación",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "SÍ",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.dispatchProductPackage({
              product_package_id: productPackageId,
              store_id: this.$route.params.id,
            }).then(() => {
              this.$swal({
                title: "Actualiza!",
                text: "Paquete de productos añadido",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then(() => window.location.reload())
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card-img-top {
  max-width: 225px;
}

.starred {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>
